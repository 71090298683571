<template>
  <div class="admin-smart-buttons">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        <!-- <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/integration')"
          icon="left"
        ></a-button> -->

        <admin-org-indicator></admin-org-indicator>

        Admin - Integration - API Clients
      </h1>
      <div class="actions">
        <a-button
          @click.prevent="load"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <a-button
          v-if="canEditApiClient"
          @click.prevent="createApiClient"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Create</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- List wrapper -->
    <div class="api-clients-list-wrapper">
      <!-- Loading -->
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No API clients -->
      <a-alert
        v-if="!isLoading && grantedApiClients.length == 0"
        type="info"
        message="No API clients to show"
      >
      </a-alert>
      <!-- / No API clients -->

      <!-- Loaded -->
      <div
        class="api-clients-list"
        v-if="!isLoading && grantedApiClients.length"
      >
        <a-row type="flex" :gutter="20">
          <a-col
            :span="colSpan"
            v-for="apiClient in grantedApiClients"
            :key="apiClient.id"
          >
            <api-client-list-item
              @selected="
                $router.push(
                  '/admin/integration/api-clients/' + apiClient.id + '/edit'
                )
              "
              :granted="true"
              :tenant-id="tenantId"
              :api-client="apiClient"
            ></api-client-list-item>
          </a-col>

          <a-col
            :span="colSpan"
            v-for="apiClient in apiClientsAddedLocallyToShow"
            :key="apiClient.id"
          >
            <api-client-list-item
              :granted="false"
              :tenant-id="tenantId"
              :api-client="apiClient"
            ></api-client-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiClientListItem from "./ApiClients/ApiClientListItem.vue";
export default {
  components: { ApiClientListItem },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.load();
  },

  watch: {
    selectedOrganisation() {
      this.load();
    },
  },

  methods: {
    ...mapActions("adminApiClients", {
      loadApiClients: "loadApiClients",
      loadApiGrants: "loadApiGrants",
      loadApiGrantPermissionOptions: "loadApiGrantPermissionOptions"
    }),

    async load() {
      this.loadApiClients();
      this.loadApiGrants();
      this.loadApiGrantPermissionOptions();
      if (this.apiClientsAddedLocallyToShow.length) {
        let vm = this;
        window.setTimeout(() => {
          vm.loadApiGrants(true);
        }, 5000);
      }
    },

    createApiClient() {
      this.$router.push("/admin/integration/api-clients/create");
    },
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
      isMyPresenceHidden: "isMyPresenceHidden"
    }),

    ...mapGetters("adminApiClients", {
      isLoading: "isLoading",
      grantedApiClients: "grantedApiClients",
      apiClientsAddedLocallyToShow: "apiClientsAddedLocallyToShow",
    }),

    ...mapGetters("permissions", {
      canEditApiClient: "canEditApiClient"
    }),

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    },
  },
};
</script>

<style scoped lang="scss">
.api-client-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}
</style>